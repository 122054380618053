import React from "react";
import OurProduct from "../pages/OurProduct";
import Offer from "./Offer";
import Map from "../pages/Map";

export default function OurProducts() {
 
  return (
    <div id="ourproducts">
      <OurProduct/>
      <Offer/>
      <Map/>
    </div>
  );
}
