import React from "react";


export default function Map() {
  return (
    <div className="map_div_main">
        <div className="d-flex align-items-center py-3 our_product_head_div">
        <h1 className="me-3">Get Directions</h1>
        <div className="flex-grow-1 throwline"></div>
      </div>

      <div className="google_map_embed" style={{ width: '100%', height: '500px', overflow: 'hidden', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)' }}>
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2475.302500289398!2d-3.495797310752312!3d51.65429769925708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486e3fbfdc43ad8f%3A0x1c63dc5b735bcbf3!2s19A%20Llewellyn%20St%2C%20Pentre%20CF41%207BX%2C%20UK!5e0!3m2!1sen!2slk!4v1725454409614!5m2!1sen!2slk"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
      
    </div>
  );
}
