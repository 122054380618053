import React from "react";
import logo from "../assets/footer_logo.svg"
import fb from "../assets/fb.svg"
import insta from "../assets/insta.svg"
import { MdArrowOutward } from "react-icons/md";
import { Link as ScrollLink } from "react-scroll";



export default function Footer() {
 
  return (
    <div className=" footer_main_div d-block d-md-flex justify-content-between align-items-center py-3">
      <div className="col-12 col-md-4 footer_1">
        <ScrollLink to="home" offset={-100}><img src={logo} style={{cursor:'pointer'}}></img></ScrollLink>
        <h3>SAT Traders</h3>
        <h4 className="col-7">19a-20 Llewellyn Street, Pentre, Mid Glamorgan, United Kingdom, CF41 7BX</h4>
        <h5>Company registration number -  12003952</h5>
        <div className="d-flex">
           <div className="me-2"> <img src={fb}></img></div>
            <img src={insta}></img>
        </div>
      </div>

      <div className="col-12 col-md-4 justify-content-center align-items-center footer_2">
        <ul className="d-block d-md-flex ps-0 py-5 py-md-0">
        <li><ScrollLink to="home" offset={-100}>Home</ScrollLink></li>
                <li><ScrollLink to="ourproducts" offset={-100}>Our Products</ScrollLink></li>
                <li><ScrollLink to="about" offset={-100}>About Us</ScrollLink></li>
                <li><ScrollLink to="contact" offset={-100}>Contact Us</ScrollLink></li>
        </ul>
      </div>

      <div >
        <a href="https://maps.app.goo.gl/kbRkCPrpW8AoUgrC6" className="d-flex justify-content-center align-items-center"  target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>
        <div className="d-flex justify-content-center align-items-center footer_3 pb-5 pb-md-0">
          
        <div className="get_bg "><h1 className="text-end">Get</h1></div>
        <h2 className="mb-0">Directions</h2>
        <MdArrowOutward className="get_icon"/>
        </div>
        </a>
        {/* <div className="d-flex  footer_3__">
            <p className="me-4"><u>Privacy Policy</u></p>
            <p><u>Terms of Service</u></p>
        </div> */}
      </div>
    </div>
  );
}
