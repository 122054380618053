import React, { useCallback, useState } from "react";
import bread from "../assets/bread.png"

export default function Bread() {
  const truncateText = useCallback((text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  }, []);

  const [showMore, setShowMore] = useState(true);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const phra = `Crafted daily by our local bakers, our artisan bread is baked to perfection, offering a soft, airy inside and a deliciously crisp crust. Perfect for sandwiches, toasts, or simply enjoyed with butter`;
  return (
    <div>
    <div className="bread_main_div" style={{ position: "relative", minHeight: "100vh",maxHeight:"100vh" }}>

      <h3>Artisan Baked Bread</h3>
      <p className="show-more" onClick={toggleShowMore}>
        {showMore ? truncateText(phra, 24) : phra}
      </p>

      <div onClick={toggleShowMore} className="read-more ">
        <u>{showMore ? "Read more" : "Show less"}</u>
      </div>

      <div className="best_selling_div_list_bread">
        <ul className="d-flex">
            <li>Freshly Baked Daily</li>
            <li>All-Natural Ingredients</li>
            
        </ul>
      </div>

      <div className="d-flex justify-content-start align-items-center div_price pb-5 ">
        <h1>£1.99</h1>
        <h2 className="ms-2 mb-0">per loaf</h2>
      </div>

      <div className="d-flex justify-content-center align-items-center"><img src={bread} style={{
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      width: "100%",
      height: "auto",
      borderBottomLeftRadius: "0.5rem",
      borderBottomRightRadius: "0.5rem"
    }}></img></div>
    </div>
    </div>
  );
}
