import React, { useCallback, useState } from "react";
import coffe from "../assets/coffe.png"

export default function Coffe() {
  const truncateText = useCallback((text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  }, []);

  const [showMore, setShowMore] = useState(true);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const phra = `Experience the rich aroma and robust flavor of our gourmet coffee beans, sourced from the finest coffee farms. Perfect for brewing at home, whether you prefer a bold espresso or a smooth cappuccino`;
  return (
    <div>
    <div className="coffe_main_div" style={{  minHeight: "100vh",maxHeight:"100vh" }}>
      <h3>Coffee</h3>
      <p className="show-more" onClick={toggleShowMore}>
        {showMore ? truncateText(phra, 20) : phra}
      </p>

      <div onClick={toggleShowMore} className="read-more ">
        <u>{showMore ? "Read more" : "Show less"}</u>
      </div>

      <div className="best_selling_div_list_coffe">
        <ul className="d-flex">
        <li>100% Organic</li>
            <li>Farm Fresh</li>
            <li>No Pesticides</li>
            
        </ul>
      </div>

      <div className="d-flex justify-content-start align-items-center div_price pb-5">
        <h1>£2.50</h1>
        <h2 className="ms-2 mb-0">per kg</h2>
      </div>

      <div className="d-flex justify-content-center align-items-cente"><img src={coffe}></img></div>
    </div>
    </div>
  );
}
