import React from "react";
import { IoMdTime } from "react-icons/io";
import { GrLocation } from "react-icons/gr";
import { MdOutlineEmail } from "react-icons/md";
import './NavTop.css'; 

export default function NavTop() {
  return (
    
    <div className="d-none d-md-block">
      <div className="d-flex navtop_main_div">
        <div className="d-flex " style={{ gap: '1.5rem' }}>

          <div className="d-flex align-items-center justify-content-center">
           
            <GrLocation className="top_nav_icon"/>
            <p className="mb-0">
              19a-20 Llewellyn Street, Pentre, Mid Glamorgan, United Kingdom,
              CF41 7BX
            </p>
          </div>

          <div className="d-flex align-items-center justify-content-center">
            
            <MdOutlineEmail className="top_nav_icon"/>
            <p className="mb-0">info@sattraders.co.uk</p>
          </div>
        </div>
        <div className="d-flex" style={{ gap: '1.5rem' }}>
          <div className="d-flex align-items-center justify-content-center">
            <IoMdTime className="top_nav_icon"/>
            <p className="mb-0">Mon-Fri: 08-00 : 22:00</p>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <IoMdTime className="top_nav_icon"/>
            <p className="mb-0">Sat-Sun: 08-00 : 16:00</p>
          </div>
        </div>
      </div>
      
      </div>
  );
}
