import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { FiNavigation } from "react-icons/fi";
import { FaTimes } from "react-icons/fa"; // Import close icon
import { Link as ScrollLink } from "react-scroll";
import logo from "../assets/logo.svg";
import { IoMdTime } from "react-icons/io";
import { SlLocationPin } from "react-icons/sl";
import { MdOutlineEmail } from "react-icons/md";
import { IoReorderThree } from "react-icons/io5";

export default function NavBar() {
  const [expanded, setExpanded] = useState(false);
  const locationUrl = "https://maps.app.goo.gl/kbRkCPrpW8AoUgrC6";

  const handleToggleClick = () => {
    setExpanded(!expanded);
  };

  const handleNavLinkClick = () => {
    setExpanded(false); // Close the menu when a link is clicked
  };

   // Determine the offset based on viewport width
   const getOffset = () => {
    if (window.innerWidth < 576) {
      return -643; 
    }
    return -108;
  };

  return (
    <Navbar expanded={expanded} expand="lg" className="navbar_main_div">
      <Navbar.Brand>
        <ScrollLink to="home" offset={getOffset()} >
          <img src={logo} alt="logo" style={{ cursor: 'pointer' }} />
        </ScrollLink>
      </Navbar.Brand>
      <Navbar.Toggle 
        aria-controls="basic-navbar-nav"
        onClick={handleToggleClick}
      >
        {expanded ? <FaTimes style={{fontSize:"1.5rem"}} /> : <IoReorderThree style={{fontSize:"1.5rem"}}/>} {/* Conditionally render icons */}
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto text-center list__">
          <Nav.Link as={ScrollLink} to="home" offset={getOffset()} onClick={handleNavLinkClick}>Home</Nav.Link>
          <Nav.Link as={ScrollLink} to="ourproducts" offset={getOffset()} onClick={handleNavLinkClick}>Our Products</Nav.Link>
          <Nav.Link as={ScrollLink} to="about" offset={getOffset()} onClick={handleNavLinkClick}>About Us</Nav.Link>
          <Nav.Link as={ScrollLink} to="contact" offset={getOffset()} onClick={handleNavLinkClick}>Contact Us</Nav.Link>
        </Nav>
        <div className="d-flex align-items-center nav_btn mx-4 mx-md-0 mt-4 mt-md-0">
          <a href={locationUrl} className="d-flex align-items-center" target="_blank" rel="noopener noreferrer">
            <FiNavigation />
            <p className="ms-2 mb-0">Get Directions</p>
          </a>
        </div>
        
        <div className="d-block d-md-none pb-5">
          <div className="flex-grow-1 throwline mt-4"></div>
          <div className="topnav__">
            <h3>Opening hours</h3>
            <div className="d-flex aalign-items-center justify-content-start py-2">
              <IoMdTime className="time_icon"/>
              <p className="mb-0 ms-1">Mon-Fri: 08:00 - 22:00</p>
            </div>
            <div className="d-flex aalign-items-center justify-content-start py-2">
              <IoMdTime className="time_icon"/>
              <p className="mb-0 ms-1">Sat-Sun: 08:00 - 16:00</p>
            </div>
          </div>
          <div className="flex-grow-1 throwline mt-4"></div>

          <div className="topnav__">
            <h3>Contact details</h3>
            <div className="d-flex aalign-items-center justify-content-start py-2">
              <SlLocationPin className="time_icon"/>
              <p className="mb-0 ms-1">19a-20 Llewellyn Street, Pentre, Mid Glamorgan, United Kingdom, CF41 7BX</p>
            </div>
            <div className="d-flex aalign-items-center justify-content-start py-2">
              <MdOutlineEmail className="time_icon"/>
              <p className="mb-0 ms-1">info@sattraders.co.uk</p>
            </div>
          </div>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}
















// import React, { useState } from "react";
// import { Navbar, Nav } from "react-bootstrap";
// import { FiNavigation } from "react-icons/fi";
// import { FaTimes } from "react-icons/fa"; // Import close icon
// import { Link as ScrollLink } from "react-scroll";
// import logo from "../assets/logo.svg";
// import { IoMdTime } from "react-icons/io";
// import { SlLocationPin } from "react-icons/sl";
// import { MdOutlineEmail } from "react-icons/md";
// import { IoReorderThree } from "react-icons/io5";

// export default function NavBar() {
//   const [expanded, setExpanded] = useState(false);
//   const [drawerOpen, setDrawerOpen] = useState(false); // State to manage drawer open/close
//   const locationUrl = "https://maps.app.goo.gl/kbRkCPrpW8AoUgrC6";

//   const handleToggleClick = () => {
//     setDrawerOpen(!drawerOpen); // Toggle the drawer
//   };

//   const handleNavLinkClick = () => {
//     setDrawerOpen(false); // Close drawer when a nav link is clicked
//   };

//   return (
//     <>
//       {/* Backdrop for when drawer is open */}
//       {drawerOpen && <div className="backdrop open" onClick={handleToggleClick}></div>}
      
//       <Navbar expanded={expanded} expand="lg" className="navbar_main_div">
//         <Navbar.Brand>
//           <ScrollLink to="home" offset={-110}>
//             <img src={logo} alt="logo" style={{ cursor: "pointer" }} />
//           </ScrollLink>
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggleClick}>
//           {drawerOpen ? <FaTimes style={{ fontSize: "1.5rem" }} /> : <IoReorderThree style={{ fontSize: "1.5rem" }} />}
//         </Navbar.Toggle>

//         {/* Drawer menu */}
//         <div className={`nav-drawer ${drawerOpen ? "open" : ""}`}>
//           <FaTimes className="close-btn" onClick={handleToggleClick} />
//           <Nav className="flex-column">
//             <Nav.Link as={ScrollLink} to="home" offset={-110} onClick={handleNavLinkClick}>
//               Home
//             </Nav.Link>
//             <Nav.Link as={ScrollLink} to="ourproducts" offset={-110} onClick={handleNavLinkClick}>
//               Our Products
//             </Nav.Link>
//             <Nav.Link as={ScrollLink} to="about" offset={-110} onClick={handleNavLinkClick}>
//               About Us
//             </Nav.Link>
//             <Nav.Link as={ScrollLink} to="contact" offset={-110} onClick={handleNavLinkClick}>
//               Contact Us
//             </Nav.Link>
//           </Nav>
//         </div>
        
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="me-auto text-center list__">
//             <Nav.Link as={ScrollLink} to="home" offset={-110}>
//               Home
//             </Nav.Link>
//             <Nav.Link as={ScrollLink} to="ourproducts" offset={-110}>
//               Our Products
//             </Nav.Link>
//             <Nav.Link as={ScrollLink} to="about" offset={-110}>
//               About Us
//             </Nav.Link>
//             <Nav.Link as={ScrollLink} to="contact" offset={-110}>
//               Contact Us
//             </Nav.Link>
//           </Nav>
//           <div className="d-flex align-items-center nav_btn mx-4 mx-md-0 mt-4 mt-md-0">
//             <a href={locationUrl} className="d-flex align-items-center" target="_blank" rel="noopener noreferrer">
//               <FiNavigation />
//               <p className="ms-2 mb-0">Get Directions</p>
//             </a>
//           </div>
//         </Navbar.Collapse>
//       </Navbar>
//     </>
//   );
// }

