import React from "react";
import honey from "../assets/honey.png";
import product2 from "../assets/ourproduct_2.png";
import product3 from "../assets/ourproduct_3.png";
import product4 from "../assets/ourproduct_4.png";
import product5 from "../assets/ourproduct_5.png";
import product6 from "../assets/ourproduct_6.png";
import product7 from "../assets/ourproduct_7.png";
import product8 from "../assets/ourproduct_8.png";
import { IoIosStar } from "react-icons/io";

const products = [
  {
    id: 1,
    name: "Locally Sourced Honey",
    image: honey,
    rating: 5,
    reviews: 3,
    price: "£5.99",
    unit: "per jar (250g)",
  },
  {
    id: 2,
    name: "Gourmet Coffee Beans",
    image: product2,
    rating: 5,
    reviews: 10,
    price: "£7.99",
    unit: "per bag (500g)",
  },
  {
    id: 3,
    name: "Freshly Squeezed Orange Juice",
    image: product3,
    rating: 5,
    reviews: 5,
    price: "£3.50",
    unit: "per bottle (1L)",
  },
  {
    id: 4,
    name: "Free-Range Eggs",
    image: product4,
    rating: 5,
    reviews: 5,
    price: "£2.99",
    unit: "per dozen",
  },
  {
    id: 5,
    name: "Dairy Milk Chocolate Bar",
    image: product5,
    rating: 5,
    reviews: 3,
    price: "£1.25",
    unit: "per bar",
  },
  {
    id: 6,
    name: "Fresh Garden Salad Mix",
    image: product6,
    rating: 5,
    reviews: 10,
    price: "£2.75",
    unit: "per bag (200g)",
  },
  {
    id: 7,
    name: "Hand-Cut Potato Crisps",
    image: product7,
    rating: 5,
    reviews: 5,
    price: "£1.50",
    unit: "per bag (150g)",
  },
  {
    id: 8,
    name: "Herbal Tea Assortment",
    image: product8,
    rating: 5,
    reviews: 5,
    price: "£4.99",
    unit: "per box (20 sachets)",
  },
];

export default function OurProduct() {
  return (
    <div className="oru_product__ py-4 py-md-0">
      <div className="d-flex align-items-center py-3 our_product_head_div">
        <h1 className="me-3">Our Products</h1>
        <div className="flex-grow-1 throwline"></div>
      </div>
      <div className="m-0 our_product_main_div">
        {products.map((product) => (
          <div className="col-12 col-md-3 our_product_div p-2 p-md-4" key={product.id}>
            <div className="d-flex align-items-center justify-content-center py-4 py-md-0">
              <img
                src={product.image}
                alt={product.name}
                style={{ width: "auto", height: "200px" }}
              />
            </div>
            <div className="our_product_sub_div">
              <h1>{product.name}</h1>
              <div className="d-flex align-items-center justify-content-start">
                {[...Array(product.rating)].map((_, i) => (
                  <IoIosStar key={i} className="rating_start" />
                ))}
                <p className="mb-0 ms-1">{product.reviews}</p>
              </div>
              <div className="d-flex align-items-center justify-content-start price_div_ourproduct">
                <h2>{product.price}</h2>
                <h3 className="ms-1 mb-0">{product.unit}</h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
