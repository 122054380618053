import React from "react";
import HomeCarousel_1 from "../carousel/HomeCarousel_1";
import HomeCarousel_2 from "../carousel/HomeCarousel_2";
import HomeCarousel_3 from "../carousel/HomeCarousel_3";


export default function Home() {
  return (
    <div className="d-block px-0 px-md-2 d-md-flex" id="home">
        <div className="pb-3 pb-md-0"><HomeCarousel_1/></div>
 
        <div className="home_carousel">
        <HomeCarousel_2/>
        <HomeCarousel_3/>
        </div>

    </div>
  );
}
