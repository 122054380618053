import React from "react";
import fire from "../assets/fire.svg";
import egg from "../assets/egg_offer.png";
import { FaArrowRight } from "react-icons/fa";



export default function EggOffer() {
  return (
    <div className="d-flex offer_egg_main_div p-4 my-2 my-md-0">
        <div className="col-7 col-md-6">
      <div className="d-flex align-items-center justify-content-start fire_div pb-3" >
        <img src={fire}></img>
        <p className="mb-0 ms-1">Only This Week</p>
      </div>
      <h2>Quality eggs at an affordable price</h2>
      <h3 className="pb-3 pt-1">Eat one every day</h3>

      <div className="d-flex  align-items-center justify-content-start pb-3" >
          <div className="d-flex offer_nav_btn"style={{ gap: '0.5rem' }}>
          <p className="mb-0">Explore Now</p>
          <FaArrowRight/>
          </div>
      
        </div>
        </div>

        <div className="d-flex justify-content-center align-items-end col-5 col-md-6">
            <img src={egg}></img>
        </div>
    </div>
  );
}
