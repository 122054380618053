import React from "react";
import Contact from "../pages/Contact";
import Footer from "../pages/Footer";
import FooterBotom from "../pages/FooterBotom";


export default function ContactUsForm() {
 
  return (
    <div className="footer_contactform" id="contact">
     <Contact/>
     <Footer/>
     <FooterBotom/>
    </div>
  );
}
