import React from "react";
import NavTop from "../pages/NavTop";
import NavBar from "../pages/NavBar";

export default function Header() {
  return (
    <div className="sticky">
        <NavTop/>
        <NavBar/>
        </div>

    
  );
}
