import React from "react";
import HomeCarousel_1 from "../carousel/HomeCarousel_1";
import HomeCarousel_2 from "../carousel/HomeCarousel_2";
import HomeCarousel_3 from "../carousel/HomeCarousel_3";
import About_STA from "../pages/About_STA";
import Middlebar from "../pages/Middlebar";
import BestSelling_product from "../pages/BestSelling_product";


export default function AboutUs() {
  return (
    <div  id="about">
     <About_STA/>
     <Middlebar/>
     <BestSelling_product/>

    </div>
  );
}
