import React from "react";
import Carousel_4 from "../carousel/Carousel_4";
import { FiNavigation } from "react-icons/fi";


export default function About_STA() {
  return (
    <div>
    <div className="d-block d-md-flex about_sta_main_div my-3 md-my-4 ">
      <div className="col-12 col-md-6 about_sta">
        <h2>About</h2>
        <h1>SAT Traders</h1>
        <h4>
          At SAT Traders, we have proudly served our community for over 10
          years, offering a wide range of fresh groceries, snacks, beverages,
          and household items. Our store is more than just a place to shop; it’s
          a hub for the local community where everyone feels welcome.
        </h4>
        <a href="https://maps.app.goo.gl/kbRkCPrpW8AoUgrC6"  target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>
        <div className="about_nav_btn align-items-center justify-content-center">
          <FiNavigation className="abou_sta_ico" style={{color:"black"}}/>
          <p className="mb-0">Get Directions</p>
          </div>
          </a>
      </div>
      <div className="col-12 col-md-6 carou__4">
        <Carousel_4 />
      </div>
    </div>
    </div>
  );
}
