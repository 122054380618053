import React from "react";
import Vegetables from "../assets/midd_1.png";
import Bakery from "../assets/midd_2.png";
import Chilled from "../assets/midd_3.png";
import Drinks from "../assets/midd_4.png";
import Snacks from "../assets/midd_5.png";
import page10_6 from "../assets/midd_6.png";

const items = [
  { src: Vegetables, label: "Vegetables" },
  { src: Bakery, label: "Bakery" },
  { src: Chilled, label: "Chilled Foods" },
  { src: Drinks, label: "Drinks" },
  { src: Snacks, label: "Snacks" },
  { src: page10_6, label: "Snacks" },
];

export default function Middlebar() {
  return (
    <div className="middlebar pt-2 pt-md-5 pb-0 pb-md-1">
      <div className="horizontal_scroll_container_middlebar">
        {items.concat(items).map((item, index) => (
          <div key={index} className="middlebar_contant px-1 py-1 me-2 me-md-5">
            <ul className="d-flex list-unstyled col-12 justify-content-between">
              <li>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="image_div_middlebar">
                    <img
                      src={item.src}
                      className="img-fluid m-3"
                      style={{ width: '40px', height: '40px' }} // Image size adjustment
                    />
                  </div>
                  <div className="mb-0 ms-3 middlebar_product_qt">
                    <h4>{item.label}</h4>
                    <h5 className="mt-2">6 products</h5>
                  </div>
                  <div className="vertical-line ms-5  "></div>
                </div>
                
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
