import React from "react";
import fire from "../assets/fire.svg";
import juce from "../assets/juce_offer.png";
import { FaArrowRight } from "react-icons/fa";
import EggOffer from "../offer/EggOffer";
import JuceOffer from "../offer/JuceOffer";
import BreadOffer from "../offer/BreadOffer";



export default function Offer() {
  return (
    <div className="d-block d-md-flex offer_div__ py-3" style={{ gap: '20px' }}>
        <EggOffer/>
        <JuceOffer/>
        <BreadOffer/>
      
    </div>
  );
}
