import './App.css';
import Home from './components/Home';
import HomeCarousel_1 from './carousel/HomeCarousel_1';
import NavBar from './pages/NavBar';
import NavTop from './pages/NavTop';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import About_STA from './pages/About_STA';
import Middlebar from './pages/Middlebar';
import BestSelling_product from './pages/BestSelling_product';
import OurProduct from './pages/OurProduct';
import EggOffer from './offer/EggOffer';
import BreadOffer from './offer/BreadOffer';
import JuceOffer from './offer/JuceOffer';
import Offer from './components/Offer';
import Map from './pages/Map';
import Contact from './pages/Contact';
import Footer from './pages/Footer';
import FooterBotom from './pages/FooterBotom';
import OurProducts from './components/OurProducts';
import ContactUsForm from './components/ContactUsForm';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import AboutUs from './components/AboutUs';




function App() {
  return (
    <div >
     <Header/>
     <Home/>
     <AboutUs/>
     <OurProducts/>
     <ContactUsForm/>
  
    
    </div>
  );
}

export default App;
